import React from 'react';
import TopLayout from './TopLayout';

require('../../src/styles/global.css');

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const shouldUpdateScroll = () => {
  const $header = document.getElementById('anchor-header-main');
  const headerTopOffset = $header.getBoundingClientRect().top;
  const shouldScrollToMainContent = headerTopOffset <= 0;

  console.log('shouldScrollToMainContent', shouldScrollToMainContent);
  console.log('headerTopOffset', headerTopOffset);

  if (shouldScrollToMainContent) {
    return 'anchor-header-main';
  } else {
    return false;
  }
};
