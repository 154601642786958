import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = props => {
  return createMuiTheme({
    mixins: {
      // toolbar: {
      //   minHeight: 80,
      //   '@media (min-width:600px)': {
      //     minHeight: 80
      //   }
      // }
    },
    palette: {
      secondary: {
        light: 'rgba(0, 0, 0, 0.20)',
        main: '#000000',
      },
      divider: '#f2f2f2',
      text: {
        secondary: 'rgba(83,83,83,1)',
      },
      background: {
        default: '#ffffff',
      },
    },
    shape: {
      borderRadius: 8,
    },
    shadows: [
      'none',
      '0 1px 4px 0 rgba(0,0,0,0.04), 0 3px 12px 0 rgba(0,0,0,0.03)',
      '0 3px 6px rgba(0,0,0,0.8), 0 3px 6px rgba(0,0,0,0.12)',
      '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
      '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    ],
    overrides: {
      MuiTab: {
        root: {
          minHeight: 56,
          fontSize: 14,
          fontWeight: 600,
          '@media screen and (min-width:960px)': {
            fontSize: 15,
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
      fontSize: 13,
      '@media screen and (min-width:600px)': {
        fontSize: 14,
      },
      body1: {
        fontSize: 14,
        lineHeight: 1.8,
        '@media screen and (min-width:600px)': {
          fontSize: 15,
        },
      },
      // body2: {
      //   fontSize: 13
      // },
      h6: {
        '@media screen and (min-width:600px)': {
          fontSize: 23,
        },
      },
      subtitle1: {
        fontSize: 14,
        fontWeight: 600,
        '@media screen and (min-width:600px)': {
          fontSize: 15,
        },
      },
      caption: {
        fontSize: 11,
        color: 'rgba(0, 0, 0, 0.35)',
        fontWeight: 600,
        '@media screen and (min-width:600px)': {
          fontSize: 12,
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  });
};
