import React from 'react';
import { ApolloProvider } from 'react-apollo';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '../../src/theme';

export default function TopLayout(props) {
  const theme = createTheme();
  const client = new ApolloClient({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
    fetch,
  });

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </ApolloProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
